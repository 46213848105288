/*! purgecss start ignore */

@tailwind base;

@layer base {
  h1 {
    @apply text-4xl;
  }
  h2 {
    @apply text-xl;
  }
  h3 {
    @apply text-lg;
  }
  a {
    @apply text-primary underline;
  }
}

@tailwind components;
@tailwind utilities;
/*! purgecss end ignore */

@layer components {
  .disable-blur {
      image-rendering: -webkit-optimize-contrast;
  } 
  .enable-pixelate {
      image-rendering: pixelated;
  }
}


/* @import url("/plugins/style.css"); */