/* root */
:root {
  --bs-primary: var(--primary) !important;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tooltip-background-color: rgba(107, 114, 128, 1);
  --tooltip-border-radius: 3px;
  --tooltip-color: rgba(255, 255, 255, 1);
}

/* main */
html, body {
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
	background: #FAFAFA;
	font-family: "Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
	font-weight: 400;
	color: #444;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

* {
	box-sizing: border-box;
}

#root {
	display: flex;
	height: 100vh;
  /* width: 100vh; */
	justify-content: space-between;
	flex-direction: column;
}

div.container:not(.logon):not(.admin) {
	margin-top:6rem;
	max-width:100%;
  /* min-width: max-content; */
}

header.extra-info + header.header + div.container:not(.logon) {
  margin-top:7.5rem;
}

a {
  color: inherit;
  text-decoration: none;
}

.logo {
  height: 1em;
}

@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance:textfield;
}

::selection {
	background: var(--primary);
	color: #fff;
}

::placeholder {
  font-style: italic;
}

/* @media (min-width: 1025px) { */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--primary);
  }
  ::-webkit-scrollbar-track {
    background:#F3F4F6;
  }
  ::-webkit-scrollbar-corner { 
    background: #F3F4F6;
  }
  html, tbody.scroll {
    scrollbar-width: thin;
    scrollbar-color: var(--primary) #F3F4F6;
  }
/* } */


*.scroll-thin  {
  scrollbar-width: thin;
  scrollbar-color: var(--primary) #F3F4F6;
}

*.scroll-thin::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}


/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  --tw-ring-color: var(--primary);
  border-color: var(--primary);
}

[type='checkbox'],
[type='radio'] {
  color: var(--primary);
}

[type='checkbox']:not(.form-check-input):focus,
[type='radio']:not(.form-check-input):focus {
  --tw-ring-color: var(--primary);
}

/* for tw-elements */
.form-switch .form-check-input {
  margin-top:0;
}

.form-check-input[type='checkbox']:checked, 
.form-check-input[type='radio']:checked {
  background-size: auto;
}

.form-check-input:checked {
  background-color: var(--primary);
  border-color: var(--primary);
}

.form-select:focus {
  box-shadow: 0 0 0 1px var(--primary);
}

/**
 * TOOLTIP
 */
[data-tooltip] {
  position: relative;
}

[data-tooltip]::after, [data-tooltip]::before, 
[data-tooltip][data-placement=top]::after, 
[data-tooltip][data-placement=top]::before {
  display: block;
  z-index: 99;
  position: absolute;
  bottom: 100%;
  left: 50%;
  padding: 0.25rem 0.5rem;
  overflow: hidden;
  transform: translate(-50%,-0.8rem);
  border-radius: var(--tooltip-border-radius);
  background: var(--tooltip-background-color);
  content: attr(data-tooltip);
  color: var(--tooltip-color);
  font-style: normal;
  font-weight: 500;
  font-size: .875rem;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
}

[data-tooltip]::after, 
[data-tooltip][data-placement=top]::after {
  padding: 0;
  transform: translate(-50%,-0.56rem);
  border-top: 0.3rem solid;
  border-right: 0.3rem solid transparent;
  border-left: 0.3rem solid transparent;
  border-radius: 0;
  background-color: transparent;
  content: "";
  color: var(--tooltip-background-color);
}

[data-tooltip]:focus::after, 
[data-tooltip]:focus::before, 
[data-tooltip]:hover::after, 
[data-tooltip]:hover::before {
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
}

[data-tooltip][data-placement=bottom]::after, 
[data-tooltip][data-placement=bottom]::before {
  top: 100%;
  bottom: auto;
  transform: translate(-50%,0.8rem);
}

[data-tooltip][data-placement=bottom]:after {
  transform: translate(-50%,0.31rem);
  border: 0.3rem solid transparent;
  border-bottom: 0.3rem solid;
}

[data-tooltip][data-placement=right]::after, 
[data-tooltip][data-placement=right]::before {
  top: auto;
  bottom:auto;
  left:100%;
  transform: translate(0.8rem, -22%);
  padding-top:3px;
}

[data-tooltip][data-placement=right]:after {
  transform: translate(0.31rem, -140%);
  border: 0.3rem solid transparent;
  border-right: 0.3rem solid;
  padding-top: 0;
}

[data-tooltip][data-placement=left]::after, 
[data-tooltip][data-placement=left]::before {
  top: auto;
  bottom:auto;
  left:auto;
  right:100%;
  transform: translate(-0.8rem, -22%);
  padding-top:3px;
}

[data-tooltip][data-placement=left]:after {
  transform: translate(-0.31rem, -140%);
  border: 0.3rem solid transparent;
  border-left: 0.3rem solid;
  padding-top:0;
}


/* data-title */
[data-title]:not([data-title=""]):hover:after {
  display:block;
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title]:after {
  display:none;
  position: absolute;
  bottom: -1.6em;
  left: 100%;
  z-index: 99999;
  visibility: hidden;
  padding: 0.25rem 0.5rem;
  border-radius: var(--tooltip-border-radius);
  background: var(--tooltip-background-color);
  content: attr(data-title);
  color: var(--tooltip-color);
  font-style: normal;
  font-weight: 500;
  font-size: .875rem;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: pre;
  opacity: 0;
  pointer-events: none;
}

[data-title]:not([data-title=""]) {
  position: relative;
}

[data-title][data-placement="left"]:after {
  left:inherit;
  bottom:inherit;
  right:110%;
  top: calc(50% - 1.1em);
}

[data-title][data-placement="bottom"]:after {
  left: calc(50%);
  bottom: -1.8em;
  transform: translateX(-50%);
}

[data-title][data-placement="top"]:after {
  top: -1.8em;
  left: calc(50%);
  bottom: inherit;
  transform: translateX(-50%);
}

[data-title][data-placement="left"][data-color="red"]:after {
  background: #ef4444;
}

/*
.dark [type=checkbox]:checked, .dark [type=radio]:checked, [type=checkbox]:checked, [type=radio]:checked {
  background-color: currentColor;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: transparent;
}

.peer:checked~.peer-checked\:after\:border-white:after {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255/var(--tw-border-opacity));
  content: var(--tw-content);
}

.peer:checked~.peer-checked\:bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: var(--primary)
}

.peer:checked~.peer-checked\:after\:translate-x-full:after {
  --tw-translate-x: 100%;
  content: var(--tw-content);
  transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:content-\[\'\'\]:after {
  --tw-content: "";
  content: var(--tw-content);
}

.after\:transition-all:after {
  content: var(--tw-content);
  transition-duration: .15s;
  transition-property: all;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
}

.after\:bg-white:after {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255/var(--tw-bg-opacity));
  content: var(--tw-content);
}

.after\:border-gray-300:after {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219/var(--tw-border-opacity));
  content: var(--tw-content);
}

.after\:border:after {
  border-width: 1px;
  content: var(--tw-content);
}

.after\:rounded-full:after {
  border-radius: 9999px;
  content: var(--tw-content);
}

.after\:w-5:after {
  content: var(--tw-content);
  width: 1.25rem;
}

.after\:h-5:after {
  content: var(--tw-content);
  height: 1.25rem;
}

.after\:top-\[2px\]:after {
  content: var(--tw-content);
  top: 2px;
}

.after\:left-\[2px\]:after {
  content: var(--tw-content);
  left: 2px;
}

.after\:absolute:after {
  content: var(--tw-content);
  position: absolute;
}

*, :after, :before {
  border: 0 solid #e5e7eb;
  box-sizing: border-box;
}

*/

/* react-calendar */
.react-calendar { 
  width: calc(320px - 3rem);
  max-width: 100%;
  color: #222;
  /* font-family: Arial, Helvetica, sans-serif; */
  line-height: 1.125em;
}
.react-calendar__navigation {
  display:flex;
  width: calc(100%);
  padding:4px 6px 8px;
}
.react-calendar__navigation button {
  color: var(--primary);
  min-width: 30px;
  background: none;
  font-size: 1em;
  padding:6px 0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
abbr[title] {
  text-decoration: none;
  font-size:0.9em;
}
 /* .react-calendar__month-view__days__day--weekend {
  color: #d10000;
 } */

.react-calendar__tile {
  max-width: 100%;
  padding: 9px 8px;
  background: none;
  text-align: center;
  line-height: 16px;
}
.react-calendar__tile abbr {
  font-size:0.9em;
}
.react-calendar__tile.react-calendar__month-view__days__day--weekend:not(.react-calendar__tile--active):not([disabled])
abbr {
  color:chocolate;
}
.react-calendar__tile.react-calendar__feasts:not(.react-calendar__tile--active):not(.react-calendar__month-view__days__day--neighboringMonth):not([disabled])
abbr {
  color:crimson;
  font-weight: 600;
}
.react-calendar__tile.react-calendar__month-view__days__day--weekend + 
.react-calendar__tile.react-calendar__month-view__days__day--weekend:not(.react-calendar__tile--active):not(.react-calendar__month-view__days__day--neighboringMonth):not([disabled]) 
abbr {
  color:crimson;
}
.react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth.react-calendar__tile--now abbr {
  color:var(--primary);
}
.react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth.react-calendar__tile--now.react-calendar__tile--active abbr {
  color:white;
}
.react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth:not(.react-calendar__tile--now):not(.react-calendar__tile--active) abbr {
  color:silver;
}

[disabled].react-calendar__tile:hover {
  cursor:inherit;
}
[disabled].react-calendar__tile abbr {
  color:silver;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #f8f8fa;
  color: var(--primary);
  border-radius: 6px;
}
.react-calendar__tile--now {
  background: var(--primary)33;
  border-radius: 6px;
  font-weight: bold;
  color: var(--primary);
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: var(--primary)33;
  border-radius: 6px;
  font-weight: bold;
  color: var(--primary);
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
}
.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive {
  font-weight:600;
}
.react-calendar__tile--active {
  background: var(--primary);
  border-radius: 6px;
  font-weight: bold;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: var(--primary);
  color: white;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
}
.react-calendar__tile--range {
  background: var(--tertiary);
  color: var(--primary);
  border-radius: 0;
}
.react-calendar__tile--rangeStart {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: var(--primary);
  color: white;
}
.react-calendar__tile--rangeEnd {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background: var(--primary);
  color: white;
}

.react-calendar__navigation__label span {
  text-transform: capitalize;
}
.react-calendar__month-view__weekdays {
  padding:4px 0;
}
.react-calendar__month-view__weekdays div {
  text-align: center;
}
.react-calendar__month-view__weekdays div abbr {
  text-transform: capitalize;
}

.react-calendar__month-view__weekdays__weekday abbr {
  font-size:0.8em;
  font-weight: 600;
}

.react-calendar__navigation__next-button,
.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev-button,
.react-calendar__navigation__prev2-button {
  display:flex;
  justify-content: center;
  align-items: center;
}

.react-calendar__navigation__next-button:hover > *,
.react-calendar__navigation__next2-button:hover > *,
.react-calendar__navigation__prev-button:hover > *,
.react-calendar__navigation__prev2-button:hover > * {
    color:var(--primary);
}

/* arrow */
.react-arrow {
  position: absolute;
  width: 10px;
  height: 10px;
}
.react-arrow:after {
  content: " ";
  position: absolute;
  top: 0;
  left: -15px;
  transform: rotate(-45deg);
  width: 10px;
  height: 10px;
  background-color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

/* IOS */
@supports (-webkit-overflow-scrolling: touch) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}

/* NORMALIZE */
.normalized {
  font-size: 14px;
  line-height: inherit;
  font-weight: inherit;
  color: black;
}
.normalized html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
.normalized body {
  margin: 0;
}
.normalized article,
.normalized aside,
.normalized details,
.normalized figcaption,
.normalized figure,
.normalized footer,
.normalized header,
.normalized hgroup,
.normalized main,
.normalized menu,
.normalized nav,
.normalized section,
.normalized summary {
  display: block;
}
.normalized audio,
.normalized canvas,
.normalized progress,
.normalized video {
  display: inline-block;
  vertical-align: baseline;
}
.normalized audio:not([controls]) {
  display: none;
  height: 0;
}
.normalized [hidden],
.normalized template {
  display: none;
}
.normalized a {
  background-color: transparent;
}
.normalized a:active,
.normalized a:hover {
  outline: 0;
}
.normalized abbr[title] {
  border-bottom: 1px dotted;
}
.normalized b,
.normalized strong {
  font-weight: bold;
}
.normalized dfn {
  font-style: italic;
}
.normalized h1 {
  font-size: 2em;
  margin: 0.67em 0;
  font-weight:500;
}
.normalized h2 {
  font-size: 1.5em;
  margin: 0.83em 0;
  font-weight:500;
}
.normalized h3 {
  font-size: 1.17em;
  margin: 1em 0;
  font-weight:500;
}
.normalized h4 {
  font-size: 1em;
  /* margin: 1.33em 0; */
  font-weight:500;
}
.normalized h5 {
  font-size: 0.83em;
  /* margin: 1.67em 0; */
  font-weight:500;
}
.normalized h6 {
  font-size: 0.75em;
  /* margin: 2.33em 0; */
  font-weight:500;
}
.normalized mark {
  background: #ff0;
  color: #000;
}
.normalized small {
  font-size: 80%;
}
.normalized sub,
.normalized sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
.normalized sup {
  top: -0.5em;
}
.normalized sub {
  bottom: -0.25em;
}
.normalized img {
  border: 0;
}
.normalized svg:not(:root) {
  overflow: hidden;
}
.normalized figure {
  margin: 1em 40px;
}
.normalized hr {
  box-sizing: content-box;
  height: 0;
}
.normalized pre {
  overflow: auto;
}
.normalized code,
.normalized kbd,
.normalized pre,
.normalized samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
.normalized button,
.normalized input,
.normalized optgroup,
.normalized select,
.normalized textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
.normalized button {
  overflow: visible;
}
.normalized button,
.normalized select {
  text-transform: none;
}
.normalized button,
.normalized html input[type="button"],
.normalized input[type="reset"],
.normalized input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
.normalized button[disabled],
.normalized html input[disabled] {
  cursor: default;
}
.normalized button::-moz-focus-inner,
.normalized input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.normalized input {
  line-height: normal;
}
.normalized input[type="checkbox"],
.normalized input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
.normalized input[type="number"]::-webkit-inner-spin-button,
.normalized input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
.normalized input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}
.normalized input[type="search"]::-webkit-search-cancel-button,
.normalized input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
.normalized fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
.normalized legend {
  border: 0;
  padding: 0;
}
.normalized textarea {
  overflow: auto;
}
.normalized optgroup {
  font-weight: bold;
}
.normalized table {
  border-collapse: collapse;
  border-spacing: 0;
}
.normalized td,
.normalized th {
  padding: 1px 2px;
}
.normalized ul,
.normalized ol {
  display: block;
  list-style: disc outside none;
  margin: 1em 0;
  padding: 0 0 0 40px;
}
.normalized ol {
  list-style-type: decimal;
}
.normalized li {
  display: list-item;
}
.normalized ul ul,
.normalized ol ul {
  list-style-type: circle;
  margin-left: 15px;
}
.normalized ol ol,
.normalized ul ol {
  list-style-type: lower-latin;
  margin-left: 15px;
}
.normalized p {
  color: black;
  font-size: inherit;
}
