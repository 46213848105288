
.fflag {
  background-image:url('./flag-icons2.png');
  background-repeat:no-repeat;
  background-size: 100% 49494%;
  /* display: inline-block; */
  overflow: hidden;
  position: relative;
  vertical-align: middle;
  box-sizing: content-box;
  box-shadow: 0 0 0 1px rgba(0,0,0,0.2);
  border:solid 0.1em white;
}
html[lang='ch'] .fflag-html, .fflag-CH,
html[lang='np'] .fflag-html, .fflag-NP {box-shadow: none!important}
html[lang='dz'] .fflag-html, .fflag-DZ {background-position:center 0.2287%}
html[lang='ao'] .fflag-html, .fflag-AO {background-position:center 0.4524%}
html[lang='bj'] .fflag-html, .fflag-BJ {background-position:center 0.6721%}
html[lang='bw'] .fflag-html, .fflag-BW {background-position:center 0.8958%}
html[lang='bf'] .fflag-html, .fflag-BF {background-position:center 1.1162%}
html[lang='bi'] .fflag-html, .fflag-BI {background-position:center 1.3379%}
html[lang='cm'] .fflag-html, .fflag-CM {background-position:center 1.5589%}
html[lang='cv'] .fflag-html, .fflag-CV {background-position:center 1.7805%}
html[lang='cf'] .fflag-html, .fflag-CF {background-position:center 2.0047%}
html[lang='td'] .fflag-html, .fflag-TD {background-position:center 2.2247%}
html[lang='cd'] .fflag-html, .fflag-CD {background-position:left 2.4467%}
html[lang='dj'] .fflag-html, .fflag-DJ {background-position:left 2.6674%}
html[lang='eg'] .fflag-html, .fflag-EG {background-position:center 2.8931%}
html[lang='gq'] .fflag-html, .fflag-GQ {background-position:center 3.1125%}
html[lang='er'] .fflag-html, .fflag-ER {background-position:left 3.3325%}
html[lang='et'] .fflag-html, .fflag-ET {background-position:center 3.5542%}
html[lang='ga'] .fflag-html, .fflag-GA {background-position:center 3.7759%}
html[lang='gm'] .fflag-html, .fflag-GM {background-position:center 4.0015%}
html[lang='gh'] .fflag-html, .fflag-GH {background-position:center 4.2229%}
html[lang='gn'] .fflag-html, .fflag-GN {background-position:center 4.441%}
html[lang='gw'] .fflag-html, .fflag-GW {background-position:left 4.66663%}
html[lang='ci'] .fflag-html, .fflag-CI {background-position:center 4.8844%}
html[lang='ke'] .fflag-html, .fflag-KE {background-position:center 5.1061%}
html[lang='ls'] .fflag-html, .fflag-LS {background-position:center 5.3298%}
html[lang='lr'] .fflag-html, .fflag-LR {background-position:left 5.5495%}
html[lang='ly'] .fflag-html, .fflag-LY {background-position:center 5.7712%}
html[lang='mg'] .fflag-html, .fflag-MG {background-position:center 5.994%}
html[lang='mw'] .fflag-html, .fflag-MW {background-position:center 6.2156%}
html[lang='ml'] .fflag-html, .fflag-ML {background-position:center 6.4363%}
html[lang='mr'] .fflag-html, .fflag-MR {background-position:center 6.658%}
html[lang='mu'] .fflag-html, .fflag-MU {background-position:center 6.8805%}
html[lang='yt'] .fflag-html, .fflag-YT {background-position:center 7.1038%}
html[lang='ma'] .fflag-html, .fflag-MA {background-position:center 7.3231%}
html[lang='mz'] .fflag-html, .fflag-MZ {background-position:left 7.5448%}
html[lang='na'] .fflag-html, .fflag-NA {background-position:left 7.7661%}
html[lang='ne'] .fflag-html, .fflag-NE {background-position:center 7.98937%}
html[lang='ng'] .fflag-html, .fflag-NG {background-position:center 8.2099%}
html[lang='cg'] .fflag-html, .fflag-CG {background-position:center 8.4316%}
html[lang='re'] .fflag-html, .fflag-RE {background-position:center 8.6533%}
html[lang='rw'] .fflag-html, .fflag-RW {background-position:right 8.875%}
html[lang='sh'] .fflag-html, .fflag-SH {background-position:center 9.0967%}
html[lang='st'] .fflag-html, .fflag-ST {background-position:center 9.32237%}
html[lang='sn'] .fflag-html, .fflag-SN {background-position:center 9.5426%}
html[lang='sc'] .fflag-html, .fflag-SC {background-position:left 9.7628%}
html[lang='sl'] .fflag-html, .fflag-SL {background-position:center 9.9845%}
html[lang='so'] .fflag-html, .fflag-SO {background-position:center 10.2052%}
html[lang='za'] .fflag-html, .fflag-ZA {background-position:left 10.4269%}
html[lang='ss'] .fflag-html, .fflag-SS {background-position:left 10.6486%}
html[lang='sd'] .fflag-html, .fflag-SD {background-position:center 10.8703%}
html[lang='sr'] .fflag-html, .fflag-SR {background-position:center 11.0945%}
html[lang='sz'] .fflag-html, .fflag-SZ {background-position:center 11.3135%}
html[lang='tg'] .fflag-html, .fflag-TG {background-position:left 11.5354%}
html[lang='tn'] .fflag-html, .fflag-TN {background-position:center 11.7593%}
html[lang='ug'] .fflag-html, .fflag-UG {background-position:center 11.9799%}
html[lang='tz'] .fflag-html, .fflag-TZ {background-position:center 12.2005%}
html[lang='eh'] .fflag-html, .fflag-EH {background-position:center 12.4222%}
html[lang='ye'] .fflag-html, .fflag-YE {background-position:center 12.644%}
html[lang='zm'] .fflag-html, .fflag-ZM {background-position:center 12.8664%}
html[lang='zw'] .fflag-html, .fflag-ZW {background-position:left 13.0873%}
html[lang='ai'] .fflag-html, .fflag-AI {background-position:center 13.309%}
html[lang='ag'] .fflag-html, .fflag-AG {background-position:center 13.5307%}
html[lang='ar'] .fflag-html, .fflag-AR {background-position:center 13.7524%}
html[lang='aw'] .fflag-html, .fflag-AW {background-position:left 13.9741%}
html[lang='bs'] .fflag-html, .fflag-BS {background-position:left 14.1958%}
html[lang='bb'] .fflag-html, .fflag-BB {background-position:center 14.4175%}
html[lang='bq'] .fflag-html, .fflag-BQ {background-position:center 14.6415%}
html[lang='bz'] .fflag-html, .fflag-BZ {background-position:center 14.8609%}
html[lang='bm'] .fflag-html, .fflag-BM {background-position:center 15.0826%}
html[lang='bo'] .fflag-html, .fflag-BO {background-position:center 15.306%}
html[lang='vg'] .fflag-html, .fflag-VG {background-position:center 15.528%}
html[lang='br'] .fflag-html, .fflag-BR {background-position:center 15.7496%}
html[lang='ca'] .fflag-html, .fflag-CA {background-position:center 15.9694%}
html[lang='ky'] .fflag-html, .fflag-KY {background-position:center 16.1911%}
html[lang='cl'] .fflag-html, .fflag-CL {background-position:left 16.4128%}
html[lang='co'] .fflag-html, .fflag-CO {background-position:left 16.6345%}
html[lang='km'] .fflag-html, .fflag-KM {background-position:center 16.8562%}
html[lang='cr'] .fflag-html, .fflag-CR {background-position:center 17.0779%}
html[lang='cu'] .fflag-html, .fflag-CU {background-position:left 17.2996%}
html[lang='cw'] .fflag-html, .fflag-CW {background-position:center 17.5213%}
html[lang='dm'] .fflag-html, .fflag-DM {background-position:center 17.743%}
html[lang='do'] .fflag-html, .fflag-DO {background-position:center 17.968%}
html[lang='ec'] .fflag-html, .fflag-EC {background-position:center 18.1864%}
html[lang='sv'] .fflag-html, .fflag-SV {background-position:center 18.4081%}
html[lang='fk'] .fflag-html, .fflag-FK {background-position:center 18.6298%}
html[lang='gf'] .fflag-html, .fflag-GF {background-position:center 18.8515%}
html[lang='gl'] .fflag-html, .fflag-GL {background-position:left 19.0732%}
html[lang='gd'] .fflag-html, .fflag-GD {background-position:center 19.2987%}
html[lang='gp'] .fflag-html, .fflag-GP {background-position:center 19.518%}
html[lang='gt'] .fflag-html, .fflag-GT {background-position:center 19.7383%}
html[lang='gy'] .fflag-html, .fflag-GY {background-position:center 19.96%}
html[lang='ht'] .fflag-html, .fflag-HT {background-position:center 20.1817%}
html[lang='hn'] .fflag-html, .fflag-HN {background-position:center 20.4034%}
html[lang='jm'] .fflag-html, .fflag-JM {background-position:center 20.6241%}
html[lang='mq'] .fflag-html, .fflag-MQ {background-position:center 20.8468%}
html[lang='mx'] .fflag-html, .fflag-MX {background-position:center 21.0685%}
html[lang='ms'] .fflag-html, .fflag-MS {background-position:center 21.2902%}
html[lang='ni'] .fflag-html, .fflag-NI {background-position:center 21.5119%}
html[lang='pa'] .fflag-html, .fflag-PA {background-position:center 21.7336%}
html[lang='py'] .fflag-html, .fflag-PY {background-position:center 21.9553%}
html[lang='pe'] .fflag-html, .fflag-PE {background-position:center 22.177%}
html[lang='pr'] .fflag-html, .fflag-PR {background-position:left 22.4002%}
html[lang='bl'] .fflag-html, .fflag-BL {background-position:center 22.6204%}
html[lang='kn'] .fflag-html, .fflag-KN {background-position:center 22.8421%}
html[lang='lc'] .fflag-html, .fflag-LC {background-position:center 23.0638%}
html[lang='pm'] .fflag-html, .fflag-PM {background-position:center 23.2855%}
html[lang='vc'] .fflag-html, .fflag-VC {background-position:center 23.5072%}
html[lang='sx'] .fflag-html, .fflag-SX {background-position:left 23.732%}
html[lang='tt'] .fflag-html, .fflag-TT {background-position:center 23.9506%}
html[lang='tc'] .fflag-html, .fflag-TC {background-position:center 24.1723%}
html[lang='us'] .fflag-html, .fflag-US {background-position:center 24.392%}
html[lang='vi'] .fflag-html, .fflag-VI {background-position:center 24.6157%}
html[lang='uy'] .fflag-html, .fflag-UY {background-position:left 24.8374%}
html[lang='ve'] .fflag-html, .fflag-VE {background-position:center 25.0591%}
html[lang='ab'] .fflag-html, .fflag-AB {background-position:center 25.279%}
html[lang='af'] .fflag-html, .fflag-AF {background-position:center 25.5025%}
html[lang='az'] .fflag-html, .fflag-AZ {background-position:center 25.7242%}
html[lang='bd'] .fflag-html, .fflag-BD {background-position:center 25.9459%}
html[lang='bt'] .fflag-html, .fflag-BT {background-position:center 26.1676%}
html[lang='bn'] .fflag-html, .fflag-BN {background-position:center 26.3885%}
html[lang='kh'] .fflag-html, .fflag-KH {background-position:center 26.611%}
html[lang='cn'] .fflag-html, .fflag-CN {background-position:left 26.8327%}
html[lang='ge'] .fflag-html, .fflag-GE {background-position:center 27.0544%}
html[lang='hk'] .fflag-html, .fflag-HK {background-position:center 27.2761%}
html[lang='in'] .fflag-html, .fflag-IN {background-position:center 27.4978%}
html[lang='id'] .fflag-html, .fflag-ID {background-position:center 27.7195%}
html[lang='jp'] .fflag-html, .fflag-JP {background-position:center 27.9412%}
html[lang='kz'] .fflag-html, .fflag-KZ {background-position:center 28.1615%}
html[lang='la'] .fflag-html, .fflag-LA {background-position:center 28.3846%}
html[lang='mo'] .fflag-html, .fflag-MO {background-position:center 28.6063%}
html[lang='my'] .fflag-html, .fflag-MY {background-position:center 28.829%}
html[lang='mv'] .fflag-html, .fflag-MV {background-position:center 29.0497%}
html[lang='mn'] .fflag-html, .fflag-MN {background-position:left 29.2714%}
html[lang='mm'] .fflag-html, .fflag-MM {background-position:center 29.4931%}
html[lang='np'] .fflag-html, .fflag-NP {background-position:left 29.7148%}
html[lang='kp'] .fflag-html, .fflag-KP {background-position:left 29.9365%}
html[lang='mp'] .fflag-html, .fflag-MP {background-position:center 30.1582%}
html[lang='pw'] .fflag-html, .fflag-PW {background-position:center 30.3799%}
html[lang='pg'] .fflag-html, .fflag-PG {background-position:center 30.6016%}
html[lang='ph'] .fflag-html, .fflag-PH {background-position:left 30.8233%}
html[lang='sg'] .fflag-html, .fflag-SG {background-position:left 31.045%}
html[lang='kr'] .fflag-html, .fflag-KR {background-position:center 31.2667%}
html[lang='lk'] .fflag-html, .fflag-LK {background-position:right 31.4884%}
html[lang='tw'] .fflag-html, .fflag-TW {background-position:left 31.7101%}
html[lang='tj'] .fflag-html, .fflag-TJ {background-position:center 31.9318%}
html[lang='th'] .fflag-html, .fflag-TH {background-position:center 32.1535%}
html[lang='tl'] .fflag-html, .fflag-TL {background-position:left 32.3752%}
html[lang='tm'] .fflag-html, .fflag-TM {background-position:center 32.5969%}
html[lang='vn'] .fflag-html, .fflag-VN {background-position:center 32.8186%}
html[lang='ax'] .fflag-html, .fflag-AX {background-position:center 33.0403%}
html[lang='al'] .fflag-html, .fflag-AL {background-position:center 33.25975%}
html[lang='ad'] .fflag-html, .fflag-AD {background-position:center 33.4837%}
html[lang='am'] .fflag-html, .fflag-AM {background-position:center 33.7054%}
html[lang='at'] .fflag-html, .fflag-AT {background-position:center 33.9271%}
html[lang='by'] .fflag-html, .fflag-BY {background-position:left 34.1488%}
html[lang='be'] .fflag-html, .fflag-BE {background-position:center 34.3705%}
html[lang='ba'] .fflag-html, .fflag-BA {background-position:center 34.5922%}
html[lang='bg'] .fflag-html, .fflag-BG {background-position:center 34.8139%}
html[lang='hr'] .fflag-html, .fflag-HR {background-position:center 35.0356%}
html[lang='cy'] .fflag-html, .fflag-CY {background-position:center 35.2555%}
html[lang='cz'] .fflag-html, .fflag-CZ {background-position:left 35.479%}
html[lang='cs'] .fflag-html, .fflag-CS {background-position:left 35.479%}
html[lang='dk'] .fflag-html, .fflag-DK {background-position:center 35.7007%}
html[lang='ee'] .fflag-html, .fflag-EE {background-position:center 35.9224%}
html[lang='fo'] .fflag-html, .fflag-FO {background-position:center 36.1441%}
html[lang='fi'] .fflag-html, .fflag-FI {background-position:center 36.3658%}
html[lang='fr'] .fflag-html, .fflag-FR {background-position:center 36.5875%}
html[lang='de'] .fflag-html, .fflag-DE {background-position:center 36.8092%}
html[lang='gi'] .fflag-html, .fflag-GI {background-position:center 37.0309%}
html[lang='gr'] .fflag-html, .fflag-GR {background-position:left 37.2526%}
html[lang='gg'] .fflag-html, .fflag-GG {background-position:center 37.4743%}
html[lang='hu'] .fflag-html, .fflag-HU {background-position:center 37.696%}
html[lang='is'] .fflag-html, .fflag-IS {background-position:center 37.9177%}
html[lang='ie'] .fflag-html, .fflag-IE {background-position:center 38.1394%}
html[lang='im'] .fflag-html, .fflag-IM {background-position:center 38.3611%}
html[lang='it'] .fflag-html, .fflag-IT {background-position:center 38.5828%}
html[lang='je'] .fflag-html, .fflag-JE {background-position:center 38.8045%}
html[lang='xk'] .fflag-html, .fflag-XK {background-position:center 39.0262%}
html[lang='lv'] .fflag-html, .fflag-LV {background-position:center 39.2479%}
html[lang='li'] .fflag-html, .fflag-LI {background-position:left 39.4696%}
html[lang='lt'] .fflag-html, .fflag-LT {background-position:center 39.6913%}
html[lang='lu'] .fflag-html, .fflag-LU {background-position:center 39.913%}
html[lang='mt'] .fflag-html, .fflag-MT {background-position:left 40.1347%}
html[lang='md'] .fflag-html, .fflag-MD {background-position:center 40.3564%}
html[lang='mc'] .fflag-html, .fflag-MC {background-position:center 40.5781%}
html[lang='me'] .fflag-html, .fflag-ME {background-position:center 40.7998%}
html[lang='nl'] .fflag-html, .fflag-NL {background-position:center 41.0215%}
html[lang='mk'] .fflag-html, .fflag-MK {background-position:center 41.2432%}
html[lang='no'] .fflag-html, .fflag-NO {background-position:center 41.4649%}
html[lang='pl'] .fflag-html, .fflag-PL {background-position:center 41.6866%}
html[lang='pt'] .fflag-html, .fflag-PT {background-position:center 41.9083%}
html[lang='ro'] .fflag-html, .fflag-RO {background-position:center 42.13%}
html[lang='ru'] .fflag-html, .fflag-RU {background-position:center 42.3517%}
html[lang='sm'] .fflag-html, .fflag-SM {background-position:center 42.5734%}
html[lang='rs'] .fflag-html, .fflag-RS {background-position:center 42.7951%}
html[lang='sk'] .fflag-html, .fflag-SK {background-position:center 43.0168%}
html[lang='si'] .fflag-html, .fflag-SI {background-position:center 43.2385%}
html[lang='es'] .fflag-html, .fflag-ES {background-position:left 43.4602%}
html[lang='se'] .fflag-html, .fflag-SE {background-position:center 43.6819%}
html[lang='ch'] .fflag-html, .fflag-CH {background-position:center 43.9036%}
html[lang='tr'] .fflag-html, .fflag-TR {background-position:center 44.1253%}
html[lang='ua'] .fflag-html, .fflag-UA {background-position:center 44.347%}
html[lang='en'] .fflag-html, .fflag-EN {background-position:center 44.5689%}
html[lang='gb'] .fflag-html, .fflag-GB {background-position:center 44.5687%}
html[lang='va'] .fflag-html, .fflag-VA {background-position:right 44.7904%}
html[lang='bh'] .fflag-html, .fflag-BH {background-position:center 45.0121%}
html[lang='ir'] .fflag-html, .fflag-IR {background-position:center 45.2338%}
html[lang='iq'] .fflag-html, .fflag-IQ {background-position:center 45.4555%}
html[lang='il'] .fflag-html, .fflag-IL {background-position:center 45.6772%}
html[lang='kw'] .fflag-html, .fflag-KW {background-position:left 45.897%}
html[lang='jo'] .fflag-html, .fflag-JO {background-position:left 46.1206%}
html[lang='kg'] .fflag-html, .fflag-KG {background-position:center 46.3423%}
html[lang='lb'] .fflag-html, .fflag-LB {background-position:center 46.561%}
html[lang='om'] .fflag-html, .fflag-OM {background-position:left 46.7857%}
html[lang='pk'] .fflag-html, .fflag-PK {background-position:center 47.0074%}
html[lang='ps'] .fflag-html, .fflag-PS {background-position:center 47.2291%}
html[lang='qa'] .fflag-html, .fflag-QA {background-position:center 47.4508%}
html[lang='sa'] .fflag-html, .fflag-SA {background-position:center 47.6725%}
html[lang='sy'] .fflag-html, .fflag-SY {background-position:center 47.8942%}
html[lang='ae'] .fflag-html, .fflag-AE {background-position:center 48.1159%}
html[lang='uz'] .fflag-html, .fflag-UZ {background-position:left 48.3376%}
html[lang='as'] .fflag-html, .fflag-AS {background-position:right 48.5593%}
html[lang='au'] .fflag-html, .fflag-AU {background-position:center 48.781%}
html[lang='cx'] .fflag-html, .fflag-CX {background-position:center 49.002%}
html[lang='cc'] .fflag-html, .fflag-CC {background-position:center 49.2244%}
html[lang='ck'] .fflag-html, .fflag-CK {background-position:center 49.4445%}
html[lang='fj'] .fflag-html, .fflag-FJ {background-position:center 49.6678%}
html[lang='pf'] .fflag-html, .fflag-PF {background-position:center 49.8895%}
html[lang='gu'] .fflag-html, .fflag-GU {background-position:center 50.1112%}
html[lang='ki'] .fflag-html, .fflag-KI {background-position:center 50.3329%}
html[lang='mh'] .fflag-html, .fflag-MH {background-position:left 50.5546%}
html[lang='fm'] .fflag-html, .fflag-FM {background-position:center 50.7763%}
html[lang='nc'] .fflag-html, .fflag-NC {background-position:center 50.998%}
html[lang='nz'] .fflag-html, .fflag-NZ {background-position:center 51.2197%}
html[lang='nr'] .fflag-html, .fflag-NR {background-position:left 51.4414%}
html[lang='nu'] .fflag-html, .fflag-NU {background-position:center 51.6631%}
html[lang='nf'] .fflag-html, .fflag-NF {background-position:center 51.8848%}
html[lang='ws'] .fflag-html, .fflag-WS {background-position:left 52.1065%}
html[lang='sb'] .fflag-html, .fflag-SB {background-position:left 52.3282%}
html[lang='tk'] .fflag-html, .fflag-TK {background-position:center 52.5499%}
html[lang='to'] .fflag-html, .fflag-TO {background-position:left 52.7716%}
html[lang='tv'] .fflag-html, .fflag-TV {background-position:center 52.9933%}
html[lang='vu'] .fflag-html, .fflag-VU {background-position:left 53.215%}
html[lang='wf'] .fflag-html, .fflag-WF {background-position:center 53.4385%}
html[lang='aq'] .fflag-html, .fflag-AQ {background-position:center 53.6584%}
html[lang='eu'] .fflag-html, .fflag-EU {background-position:center 53.875%}
html[lang='jr'] .fflag-html, .fflag-JR {background-position:center 54.099%}
html[lang='pi'] .fflag-html, .fflag-PI {background-position:center 54.099%}
html[lang='oly'] .fflag-html, .fflag-OLY {background-position:center 54.32%}
html[lang='un'] .fflag-html, .fflag-UN {background-position:center 54.54%}

.fflag.ff-sm {width: 18px;height: 11px}
.fflag.ff-md {width: 27px;height: 17px}
.fflag.ff-lg {width: 42px;height: 27px}
.fflag.ff-xl {width: 60px;height: 37px}

